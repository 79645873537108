export default {
  contentBoxesV1: {
    container: {
      // padding: '3rem 1rem',
      margin: '1rem 0rem 4rem'
    },
    contentBoxes: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '0 auto',
      'a:nth-of-type(even)': {
        position: ['', '', 'relative'],
        top: ['', '', '7rem', '10rem']
      },
      paddingBottom: ['', '', '7rem', '10rem']
    },
    box: {
      padding: '1rem',
      width: ['', '', '50%'],
      display: 'flex',
      flexDirection: 'column',
      '& :last-child': {
        marginBottom: '0px'
      }
    },
    image: {
      marginBottom: 1,
      height: ['275px'],
      objectFit: 'cover'
    },
    date: {
      marginBottom: 1,
      fontSize: 2,
      fontWeight: 'bold'
    },
    title: {
      margin: '0.5rem 0rem',
      padding: '0.5rem 0rem',
      fontWeight: '500',
      marginBottom: '1rem',
      textTransform: 'uppercase',
      borderBottom: '1px solid',
      borderTop: '1px solid'
    },
    subtitle: {
      marginBottom: 1
    },
    text: {
      marginBottom: 1
    }
  }
}
