export default {
  primary: {
    // backgroundColor: 'primary',
    color: 'dark',
    border: 'none',
    borderTop: 'solid 2px',
    borderBottom: 'solid 2px',
    borderColor: 'black',

    ':hover': {
      // backgroundColor: 'rgba(0,0,0,0.25)',
      backgroundColor: 'black',
      borderColor: 'primary',
      color: 'white'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
