export default {
  pageHeroV1: {
    container: {
      height: '70vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '3.5rem'],
        marginBottom: '2rem',
        letterSpacing: '3px',
        textTransform: 'uppercase',
        color: 'secondary',
        borderColor: 'secondary',
        backgroundColor: 'rgba(0,0,0,0.35)',
        padding: '0.25rem 1rem',
        borderTop: '2px solid white',
        borderBottom: '2px solid white'
      },
      h2: {
        marginBottom: 4
      }
    }
  }
}
