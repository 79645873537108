export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {},

  footer: {
    maxWidth: 'unset',
    '.column': {
      paddingLeft: ['', '', '', '3rem', '4rem']
    },
    '.socialContainer': {
      margin: ['', '', '', '1rem']
    }
  },

  ctaWidget: {},

  title: {
    fontSize: ['2rem', '', '3rem'],
    textAlign: 'left',
    border: 'none',
    borderBottom: '1px solid white',
    paddingLeft: '0rem',
    width: 'fit-content',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    marginBottom: '2rem',
    padding: '0.75rem 0rem'
  },
  subtitle: {
    fontSize: ['1.5rem', '', '2rem'],
    color: 'primary',
    fontWeight: '700',
    fontFamily: 'heading'
  },
  text: {
    // color: 'white',
  },

  content: {
    margin: ['', '', '', '0rem 1rem'],
    padding: ['1rem', '', '', '2.5rem'],
    border: ['', '', '', 'solid 1px'],
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
    textAlign: 'left'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      margin: ['', '', '0rem 0rem 0rem 1rem']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '', '5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  homepageHeroShout: {},

  homepageShout: {},

  homepageAbout: {
    maxHeight: ['', '', '', '75vh'],
    '.imageContainer': {
      minHeight: ['', '', '', '50vh']
    },
    '.content': {
      variant: 'customVariants.content'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageMenu: {
    maxHeight: ['', '', '', '75vh'],
    '.imageContainer': {
      minHeight: ['', '', '', '50vh']
    },
    '.content': {
      variant: 'customVariants.content'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageGallery: {
    maxHeight: ['', '', '', '75vh'],
    '.imageContainer': {
      minHeight: ['', '', '', '50vh']
    },
    '.content': {
      variant: 'customVariants.content'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageEvents: {
    maxHeight: ['', '', '', '75vh'],
    '.imageContainer': {
      minHeight: ['', '', '', '50vh']
    },
    '.content': {
      variant: 'customVariants.content'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageTitleBlock: {
    padding: ['1rem', '2rem 1rem', '2rem'],
    '.section': {
      alignItems: 'flex-start',
      margin: '1rem',
      maxWidth: ['', '', '70%']
    },
    '.title': {
      textAlign: 'left',
      fontSize: ['2rem', '3rem', '4.5rem'],
      letterSpacing: '5px'
    },
    '.subtitle': {
      textAlign: 'left',
      color: 'primary',
      borderBottom: '2px solid',
      paddingBottom: '0.5rem'
    }
  },

  // homepageContentBoxes: {}, // ! shadowed styles
  homepageContactUs: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      fontSize: ['', '', '4.5rem'],
      borderTop: '2px solid white',
      borderBottom: '2px solid white',
      padding: '2rem 1rem'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.logoContainer .logo': {
      filter: 'brightness(0)'
    },
    '.title': {
      borderBottom: '1px solid'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      textTransform: 'uppercase',
      color: 'white'
    },
    backgroundPosition: 'center center',
    backgroundColor: '#1e1e1e',
    color: 'white',
    padding: ['3rem 1rem', '', '9rem 1rem'],
    '.inputField, .textField ': {
      margin: '1rem 0rem',
      fontWeight: '300',
      border: 'none',
      borderBottom: 'solid 1px',
      borderColor: 'white',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'lightgrey'
      }
    },
    '.submitBtn': {
      variant: 'buttons.primary',
      backgroundColor: 'primary',
      borderRadius: '0px',
      color: 'white',
      borderColor: 'white',
      marginTop: '2rem'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem'],
      borderTop: 'solid 1px',
      borderBottom: 'solid 1px'
    },
    '.dayofWeekText': {
      marginBottom: '1rem'
    }
  }
}
